import { PHOTO_TRANSMISSION_PLAN } from 'spypoint/src/checkout-module/order/core/productTypes'
import PrimaryButton from 'shared-module/new-components/buttons/PrimaryButton'
import { useCart } from 'spypoint/src/checkout-module/order/core/cart.hooks'
import useSelectedCamera from 'camera-module/camera/core/useSelectedCamera'
import useUser from 'user-module/user/core/useUser'
import { useTranslation } from 'react-i18next'

const AddToCartButton = ({ onSelectPlan, eCommerceEnabled, plan, periodType, addPhotoTransmissionPlanToBasket }) => {
  const { addToCart, navigateToCart } = useCart()
  const { t } = useTranslation()
  const selectedCamera = useSelectedCamera()
  const user = useUser()

  const addPhotoTransmissionPlanToCart = () => {
    addToCart({
      productId: PHOTO_TRANSMISSION_PLAN,
      cameraId: selectedCamera.id,
      paymentFrequency: periodType,
      plan: plan.id,
    })
    navigateToCart()
  }

  if (eCommerceEnabled) {
    return <PrimaryButton onClick={() => addPhotoTransmissionPlanToBasket(plan)}>{ t('app:checkout_process.add_to_cart') }</PrimaryButton>
  }

  if (user.cartEnabled && !eCommerceEnabled) {
    return <PrimaryButton onClick={addPhotoTransmissionPlanToCart} id={`${plan.id}_add_to_cart`}>{ t('app:checkout_process.add_to_cart') }</PrimaryButton>
  }
  return <PrimaryButton onClick={() => onSelectPlan({ plan, frequency: periodType })} id={`${plan.id}_buy`}>{ t('app:checkout_process.add_to_cart') }</PrimaryButton>
}

export default AddToCartButton
