import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import AppButton from 'shared-module/components/button/AppButton'
import PrimaryButton from 'shared-module/components/button/PrimaryButton'
import { isExternalWebApp } from 'shared-module/webview/isExternalWebApp'
import eCommerceActions from 'ecommerce-module/core/eCommerce.actions'
import useUser from 'user-module/user/core/useUser'
import { useECommerceFlag } from '../../../../ecommerce-module/hooks/UseEcommerceFlag'
const useStyles = makeStyles((theme) => ({
  continueButton: {
    backgroundColor: theme.palette.background.tile,
    marginRight: 15,
    '&:hover': {
      background: theme.palette.background.paper,
    },
  },
  continueItem: {
    paddingRight: 15,
    [theme.breakpoints.down('xs')]: {
      paddingRight: 0,
      marginBottom: 10,
    },
  },
  cartButtons: {
    marginBottom: 15,
    [theme.breakpoints.down('xs')]: {
      padding: '0px 10px',
    },
  },
}))
const CartButtons = ({ isUnavailableItemInCart }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const user = useUser()
  const history = useHistory()
  const location = useLocation()
  const isExternal = isExternalWebApp()
  const eCommerceEnabled = useECommerceFlag()

  const goBack = () => {
    location.state?.fromCheckout
      ? history.push('/')
      : history.goBack()
  }
  const goToCheckout = () => {
    history.push({
      pathname: '/checkout',
      search: location.search,
    })
  }
  const payNow = () => {
    eCommerceActions.proceedToPayment(user)
  }
  return (
    <Grid
      container
      item
      direction="row"
      justifyContent="center"
      alignItems="flex-end"
      xs={12}
      sm={8}
      md={6}
      lg={4}
      xl={3}
      className={classes.cartButtons}
    >
      { !isExternal && (
        <Grid item xs={12} sm={6} className={classes.continueItem}>
          <AppButton
            fullWidth
            color="secondary"
            className={classes.continueButton}
            value={t('app:checkout_process.cart.continue_shopping.button')}
            onClick={goBack}
            style={{ whiteSpace: 'nowrap' }}
          />
        </Grid>
      ) }
      <Grid item xs={12} sm={isExternal ? 12 : 6} style={{ padding: 0 }}>
        <PrimaryButton
          fullWidth
          disabled={isUnavailableItemInCart}
          value={t('menu.checkout')}
          onClick={eCommerceEnabled ? payNow : goToCheckout}
          style={{ whiteSpace: 'nowrap' }}
        />
      </Grid>
    </Grid>
  )
}
export default CartButtons
