import { useTranslation } from 'react-i18next'
import { Redirect, useLocation } from 'react-router-dom'

import { Box, Container } from '@material-ui/core'
import CTabs from 'storybook-component-module/src/components/tabs/CTabs'

import useUser from 'user-module/user/core/useUser'
import UserIcon from 'assets/icons/navigation/UserIcon'
import { getUserCurrency } from 'shared-module/common-utils'
import CAppBar from 'storybook-component-module/src/components/containers/CAppBar'

import SpydollarsBar from '../account/header/SpydollarsBar'
import PaymentPanel from '../account/payment/PaymentPanel'
import ProfilePanel from '../account/profile/ProfilePanel'
import ManagementPanel from 'user-module/account/management/ManagementPanel'
import { getAccountDashboardTabs, getCurrentTabPath } from './SpypointAccountHelpers'
import PlansAndSubscriptionPanel from '../account/plan-and-subscription/PlansAndSubscriptionPanel'
import { useECommerceFlag } from '../../ecommerce-module/hooks/UseEcommerceFlag'

const SpypointAccountPage = () => {
  const user = useUser()
  const { t } = useTranslation()
  const currency = getUserCurrency()
  const { pathname } = useLocation()
  const eCommerceEnabled = useECommerceFlag()

  const tabs = getAccountDashboardTabs(eCommerceEnabled)
  const tabIndex = getCurrentTabPath(tabs, pathname)

  const routeSelectedTable = () => {
    if (pathname === tabs[0].path) { return <ProfilePanel /> }
    if (pathname === tabs[1].path && !tabs[1].disabled) { return <PlansAndSubscriptionPanel /> }
    if (pathname === tabs[2].path && !tabs[2].disabled) { return <PaymentPanel /> }
    if (pathname === tabs[3].path && !tabs[3].disabled) { return <ManagementPanel /> }

    return <Redirect to="/account/profile" />
  }

  return (
    <Box sx={{ marginTop: { xs: '103px', sm: '128px' } }} data-testid="account">
      <CAppBar Icon={UserIcon} title={t('app:menu.account')} />
      { !!user.spydollars && <SpydollarsBar spydolars={user.spydollars} currency={currency} /> }

      <Container maxWidth="lg" style={{ paddingTop: 32 }}>
        <CTabs tabs={tabs.filter(({ disabled }) => !disabled)} tabIndex={tabIndex} />

        { routeSelectedTable() }
      </Container>
    </Box>
  )
}

export default SpypointAccountPage
