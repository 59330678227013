import SpypointCameraStatusTile from '../SpypointCameraStatusTile'

const SpypointMemoryTile = ({ value, disable, camera }) => (
  <SpypointCameraStatusTile
    label="app:camera.sd_card"
    icon={camera?.config?.encryption ? '/images/status/encrypt.svg' : '/images/status/sdcard.svg'}
    value={`${value.percentage}%`}
    disable={disable}
  />
)

export default SpypointMemoryTile
