import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useCart } from 'spypoint/src/checkout-module/order/core/cart.hooks'
import Amount from 'shared-module/components/amount/Amount'
import useUser from 'user-module/user/core/useUser'
import LineTitle from 'spypoint/src/checkout-module/order/ui/cart-lines/LineTitle'
import { useECommerceFlag } from '../../../../../ecommerce-module/hooks/UseEcommerceFlag'
import { useSelector } from 'react-redux'
import { getProductItemsSelector } from 'ecommerce-module/core/eCommerce.selectors'

const useStyles = makeStyles((theme) => ({
  taxes: {
    color: theme.palette.secondary.main,
  },
}))
const CheckoutTotalLine = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const eCommerceEnabled = useECommerceFlag()
  const user = useUser()
  const {
    cartTotal,
    cartTotalTaxes,
    cartTotalBeforeTaxes,
  } = useCart()
  const { productSubTotal } = useSelector(getProductItemsSelector)
  return (
    <Grid container justifyContent="space-between" spacing={3}>
      { cartTotal >= 0 && (
        <>
          <LineTitle>{ t('app:checkout_process.cart.total_before_tax')
            .toLocaleUpperCase() }
          </LineTitle>
          <LineTitle align="right">
            <Amount
              value={eCommerceEnabled ? productSubTotal : cartTotalBeforeTaxes}
              currencyCode={user.currencyCode}
            />
          </LineTitle>
        </>
      ) }
      { user.isAddressComplete && cartTotalTaxes && (
        <>
          { cartTotalTaxes.map(tax => {
            return (
              <Fragment key={tax.type}>
                <LineTitle>
                  <span className={classes.taxes}>
                    { tax.type.toLocaleUpperCase() }
                  </span>
                </LineTitle>
                <LineTitle align="right">
                  <span className={classes.taxes}>
                    <Amount
                      value={tax.amount}
                      currencyCode={user.currencyCode}
                    />
                  </span>
                </LineTitle>
              </Fragment>
            )
          }) }

          <LineTitle bold>{ t('app:checkout_process.cart.total')
            .toLocaleUpperCase() }
          </LineTitle>
          <LineTitle align="right" bold>
            <Amount value={cartTotal} currencyCode={user.currencyCode} />
          </LineTitle>
        </>
      ) }
    </Grid>
  )
}

export default CheckoutTotalLine
