import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import OldCartPromoCode from './OldCartPromoCode'
import { FormDataContext } from 'shared-module/new-components/form/FormDataContext'
import { useECommerceFlag } from 'spypoint/src/ecommerce-module/hooks/UseEcommerceFlag'

const useStyles = makeStyles((theme) => ({
  paperWrapper: {
    padding: 30,
    marginTop: 20,
    marginBottom: 20,
    width: '100%',
    background: theme.palette.primary.contrastText,
  },
}))

const CartSection = ({ children }) => {
  const classes = useStyles()
  const ecommerceEnabled = useECommerceFlag()

  return (
    <Grid
      item
      container
      display="flex"
      justifyContent="center"
      xs={12}
      sm={8}
      md={6}
      lg={4}
      xl={3}
    >
      <div className={classes.paperWrapper}>
        { children }
      </div>
      <FormDataContext>
        { !ecommerceEnabled && <OldCartPromoCode /> }
      </FormDataContext>
    </Grid>
  )
}

export default CartSection
