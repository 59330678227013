import eCommerceState from 'ecommerce-module/core/eCommerce.state'
import * as actions from 'ecommerce-module/core/eCommerce.actions'

const EcommerceReducer = (state = eCommerceState.defaultState, action) => {
  switch (action.type) {
    case actions.SET_PRODUCTS:
      return { ...state }
    case actions.SET_PRODUCT_ITEMS:
      return { ...state, basketId: action.basketId, productItems: action.productItems, productSubTotal: action.productItems.productSubTotal, productTotal: action.productItems.productTotal }
    default:
      return state
  }
}

export default EcommerceReducer
