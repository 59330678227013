import { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import ReCAPTCHA from 'react-google-recaptcha'

import Box from '@material-ui/core/Box'

import Head from 'shared-module/new-components/head/Head'
import Title from 'shared-module/new-components/layout/Title'
import ForwardBackLink from 'shared-module/components/FowardBackLink'
import LoginRegisterWrapper from 'user-module/user/ui/LoginRegisterWrapper'
import { FormDataContext } from 'shared-module/new-components/form/FormDataContext'
import { makeStyles } from '@material-ui/core/styles'
import RegisterButton from 'user-module/user/ui/register/components/RegisterButton'
import PasswordField from 'storybook-component-module/src/wrappers/fields/PasswordField'
import EmailField from 'storybook-component-module/src/wrappers/fields/EmailField'
import PhoneNumberField from 'storybook-component-module/src/wrappers/fields/PhoneNumberField'
import CountryField from 'storybook-component-module/src/wrappers/fields/CountryField'
import FirstNameField from 'storybook-component-module/src/wrappers/fields/FirstNameField'
import LastNameField from 'storybook-component-module/src/wrappers/fields/LastNameField'
import TermsCheckbox from 'storybook-component-module/src/wrappers/fields/TermsCheckbox'
import { VoskerInputStyles } from 'vosker/src/components/buttons/VoskerInputField'
import appConfig from 'shared-module/config/appConfig'
import switches from 'shared-module/config/switches'
import clsx from 'clsx'
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useLDFlags } from 'spypoint/src/launchDarkly-module/launchDarklyFlagsContext'

const useStyles = makeStyles(theme => ({
  link: {
    color: `${theme.palette.status?.info}`,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  input: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: theme.palette.background?.paper,
      paddingRight: 0,
    },
    '& .MuiOutlinedInput-root.Mui-error': {
      marginBottom: '0.25rem',
    },
  },
  label: {
    marginTop: 10,
  },
  recaptcha: {
    marginBottom: 20,
  },
}))

const RegisterPage = ({ isComplexPassword = false, isNewUi = false, isVosker, googleReCaptchaProps }) => {
  const { t } = useTranslation()
  const location = useLocation()
  const classes = useStyles()
  const importedClasses = VoskerInputStyles()
  const [checked, setChecked] = useState(false)
  const [checkedEmail, setCheckedEmail] = useState(false)
  const [displayRecaptchaV2, setDisplayRecaptchaV2] = useState(false)
  const [tokenV2, setTokenV2] = useState(null)
  const [tokenV3, setTokenV3] = useState(null)
  const { flags } = useLDFlags()
  const useV2 = flags?.recaptcha?.useV2
  const useV3 = flags?.recaptcha?.useV3
  const consentEnabled = appConfig.consent.switch === switches.on
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    localStorage.removeItem('recaptcha_token')
    localStorage.removeItem('recaptcha_version')
  }, [])
  const onChange = (value) => {
    localStorage.removeItem('recaptcha_version')
    localStorage.setItem('recaptcha_token', value)
    localStorage.setItem('recaptcha_version', 'v2')
    setTokenV2(value)
  }
  const getToken = useCallback(async () => {
    const _token = await googleReCaptchaProps?.executeRecaptcha('homepage')
    setTokenV3(_token)
    localStorage.removeItem('recaptcha_version')
    localStorage.setItem('recaptcha_token', _token)
    localStorage.setItem('recaptcha_version', 'v3')
  }, [googleReCaptchaProps])

  useEffect(() => {
    useV3 && getToken()
  }, [getToken])

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    localStorage.removeItem('recaptcha_token')
  }, [])

  return (
    <LoginRegisterWrapper>
      <Head title={t('app:register.head.title')} />
      <Title>{ t('register.title') }</Title>
      <Box component="div" mb={1}>
        { t('register.login.title') }
        <Box ml={1} component="span" display="inline-block">
          <ForwardBackLink id="lnk_login" pathname="/login" underline="none" search={location.search} className={isNewUi ? classes.link : ''}>
            { t('register.login_to_my_account') }
          </ForwardBackLink>
        </Box>
      </Box>
      <FormDataContext>
        { consentEnabled && (
          <>
            <FirstNameField
              fieldName="firstName"
              className={isNewUi ? clsx(importedClasses.input, classes.input) : ''}
              validations
              isNewUi={isNewUi}
            />
            <LastNameField
              fieldName="lastName"
              className={isNewUi ? clsx(importedClasses.input, classes.input) : ''}
              validations
              isNewUi={isNewUi}
            />
          </>
        ) }
        <EmailField
          fieldName="email"
          className={isNewUi ? clsx(importedClasses.input, classes.input) : ''}
          validations
          isNewUi={isNewUi}
        />

        <PasswordField
          fieldName="password"
          showHints
          validations
          className={isNewUi ? clsx(importedClasses.input, classes.input) : ''}
          isComplexPassword={isComplexPassword}
          isNewUi={isNewUi}
        />

        <CountryField
          fieldName="country"
          className={isNewUi ? clsx(importedClasses.input, classes.input) : ''}
          validations
          isNewUi={isNewUi}
        />

        <PhoneNumberField
          fieldName="phone"
          className={isNewUi ? clsx(importedClasses.input, classes.input) : ''}
          validations
          isNewUi={isNewUi}
        />
        { consentEnabled && (
          <TermsCheckbox
            checked={checked}
            setChecked={setChecked}
            fieldName="consent"
            validations
            isNewUi={isNewUi}
          />
        ) }
        { displayRecaptchaV2 && useV2 && (
          <div className={classes.recaptcha}>
            <ReCAPTCHA
              sitekey="6LdVqp0qAAAAAAnfSdEmiiDxYVoVrrAC-kh6xlJX"
              onChange={onChange}
              theme="dark"
            />
          </div>
        ) }
        <RegisterButton consentEnabled={consentEnabled} checked={checked} checkedEmail={checkedEmail} isNewUi={isNewUi} tokenV2={tokenV2} displayRecaptchaV2={displayRecaptchaV2} setDisplayRecaptchaV2={setDisplayRecaptchaV2} />
      </FormDataContext>
    </LoginRegisterWrapper>
  )
}
export default withGoogleReCaptcha(RegisterPage)
