import appConfig from 'shared-module/config/appConfig'
import switches from 'shared-module/config/switches'

export const getAccountDashboardTabs = (ecommerceEnabled) => [
  {
    id: 'profile',
    path: '/account/profile',
    label: 'app:account.profile.title',
    disabled: false,
  },
  {
    id: 'plans-and-subscriptions',
    path: '/account/plans-and-subscriptions',
    label: 'app:account.plans-and-subscriptions.title',
    disabled: appConfig.accountDashboard.plansAndSubscriptions.switch === switches.off,
  },
  {
    id: 'payment',
    path: '/account/payment',
    label: 'app:account.payment.title',
    disabled: ecommerceEnabled,
  },
  {
    id: 'management',
    path: '/account/management',
    label: 'app:account.management.title',
    disabled: true,
  },
]

export const getCurrentTabPath = (tabs, pathname) => (
  tabs.filter(({ disabled }) => !disabled).map(tab => tab.path).includes(pathname) ? pathname : tabs[0].path
)
