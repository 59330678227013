export const eCommerceTokenID = 'salesForceToken'
export const eCommerceRefreshTokenID = 'salesForceRefreshToken'

const salesForcePlansIds = 'D06150,D06151'
const salesForceAddonsIds = 'D06251'
const salesForceInsidersClub = 'D06100'
export const getProductItemsSelector = state => ({
  basketId: state.ecommerceReducer?.productItems?.basketId,
  productItems: state.ecommerceReducer?.productItems?.productItems,
  productSubTotal: state.ecommerceReducer?.productItems?.productSubTotal,
  productTotal: state.ecommerceReducer?.productItems?.productTotal,
})

const commerceSelector = { salesForcePlansIds, salesForceAddonsIds, salesForceInsidersClub, getProductItemsSelector }
export default commerceSelector
